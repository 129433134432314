import { ReactNode } from 'react';
import OrgPickerDialog from 'src/components/OrgPickerDialog';
import { useAppDispatch } from 'src/hooks/redux';
import { cancelSelectingOrg, useAccountState } from 'src/redux/slices/accountSlice';

type CurrentOrganizationGuardProps = {
  children: ReactNode;
};

export default function CurrentOrganizationGuard({ children }: CurrentOrganizationGuardProps) {
  const accountState = useAccountState();
  const dispatch = useAppDispatch();

  if (!accountState.currentOrg || accountState.selectingOrg) {
    return (
      <>
        {children}
        <OrgPickerDialog
          canCancel={accountState.currentOrg !== undefined}
          onCancel={() => dispatch(cancelSelectingOrg())}
        />
      </>
    );
  }

  return <>{children}</>;
}
