import { Avatar, Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/redux';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { startSelectingOrg, useCurrentOrg } from 'src/redux/slices/accountSlice';
import { logOut, useCurrentUser } from 'src/redux/slices/authSlice';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';

export default function AccountPopover() {
  const location = useLocation();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const currentUser = useCurrentUser();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const currentOrg = useCurrentOrg();
  const dispatch = useAppDispatch();

  const canSelectOrg = !/^\/uusi_organisaatio\/?/.test(location.pathname) && !!currentUser;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      logOut(dispatch);
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Uloskirjautuminen epäonnistui', { variant: 'error' });
    }
  };

  const handleLogin = async () => {
    try {
      logOut(dispatch);
    } catch (error) {
      console.error(error);
    }
    if (isMountedRef.current) {
      navigate('/');
      handleClose();
    }
  };

  const handleOrgSelection = () => {
    dispatch(startSelectingOrg());
  }

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={`${process.env.PUBLIC_URL}/icons/ic_user.svg`}
          alt={currentUser?.name ?? currentUser?.email}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {currentUser
          ? <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {currentUser?.name ?? currentUser?.email}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {currentOrg?.name}
              </Typography>
            </Box>

            <Divider />
            <Stack spacing={0.5} sx={{ p: 1 }}>
              {canSelectOrg && (
                <MenuItem onClick={handleOrgSelection} sx={{ typography: 'body2' }}>
                  Valitse organisaatio
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout} sx={{ typography: 'body2' }}>
                Kirjaudu ulos
              </MenuItem>
            </Stack>
          </>
          : <>
            <Stack spacing={0.5} sx={{ p: 1 }}>
              <MenuItem onClick={handleLogin} sx={{ typography: 'body2' }}>
                Kirjaudu sisään
              </MenuItem>
            </Stack>
          </>
        }
      </MenuPopover>
    </>
  );
}
