import { ClientConfig } from "../../server/src/shared/config";

export const DRAWER_WIDTH = 260;

export const DASHBOARD_HEADER_MOBILE = 64;
export const DASHBOARD_HEADER_DESKTOP = 92;
export const DASHBOARD_NAVBAR_WIDTH = 280;
export const DASHBOARD_NAVBAR_COLLAPSE_WIDTH = 88;

export const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48;
export const DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40;
export const DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 22;

export const MAIN_HEADER_DESKTOP = 88;
export const MAIN_HEADER_MOBILE = 64;

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// Prefer `useClientConfig` for future-proofing.
export function _getClientConfigWhereHooksCannotBeUsed() {
  return (window as any)._cashpulseClientConfig as ClientConfig;
}

export function useClientConfig() {
  const config = (window as any)._cashpulseClientConfig as ClientConfig;
  if (!config) {
    throw new Error("Client config failed to load");
  }
  return config;
};
