// @mui
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

// ----------------------------------------------------------------------

export interface AlertDialogProps {
  active?: boolean;
  title: string;
  text: string;
  onOk: () => void;
  onCancel?: () => void;
}

export default function AlertDialog(props: AlertDialogProps) {
  const { active, title, text, onOk, onCancel } = props;

  return (
    <Dialog open={active ?? true} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <Box pt={1} pb={1}>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        {onCancel && (
          <Button onClick={onCancel} autoFocus>
            Peruuta
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
