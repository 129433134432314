import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { check } from "src/shared/misc";

export function usePoll({ enabled, intervalSeconds, onTick }: { enabled?: boolean, intervalSeconds: number, onTick: () => void | Promise<void> }) {
  const nextTick = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (enabled === false) {
      if (nextTick.current !== undefined) {
        nextTick.current = undefined;
      }
      return;
    }

    if (nextTick.current === undefined) {
      nextTick.current = Date.now() + intervalSeconds * 1000;
    }

    let gone = false;
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
    const startTimeout = () => {
      check(nextTick.current !== undefined);
      timeout = setTimeout(async () => {
        if (!gone) {
          try {
            await onTick();
          } finally {
            if (!gone) {
              nextTick.current = Date.now() + intervalSeconds * 1000;
              startTimeout();
            }
          }
        }
      }, nextTick.current - Date.now());
    };
    startTimeout();

    return () => {
      gone = true;
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [enabled, intervalSeconds, onTick]);
}

export function usePollUntilSettled<T>({ enabled, intervalSeconds, secondsUntilSettled, getCurrent, onCheckedNotSettledYet, onSettled, equals }: {
  enabled?: boolean,
  intervalSeconds: number,
  secondsUntilSettled: number,
  getCurrent: () => T,
  onCheckedNotSettledYet?: () => void,
  onSettled: () => void,
  equals?: (a: T, b: T) => boolean,
}): { restartPolling: () => void } {
  const prevValue = useRef(getCurrent());
  const lastChanged = useRef(Date.now());
  const [settled, setSettled] = useState(false);

  equals = equals ?? _.isEqual;
  usePoll({
    enabled: enabled !== false && !settled,
    intervalSeconds,
    onTick: () => {
      const newValue = getCurrent();
      if (equals!(newValue, prevValue.current)) {
        if (Date.now() - lastChanged.current > secondsUntilSettled * 1000) {
          setSettled(true);
          onSettled();
        }
      } else {
        prevValue.current = newValue;
        lastChanged.current = Date.now();
        onCheckedNotSettledYet?.();
      }
    },
  });

  return {
    restartPolling: () => {
      setSettled(false);
    }
  };
}
