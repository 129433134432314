import { differenceInDays } from "date-fns";
import { appApi } from "src/api/api";
import { useCurrentOrg } from "src/redux/slices/accountSlice";

export interface CurrentSubscriptionInfo {
  endsAt: Date,
  daysLeft: number,
}

export function useCurrentSubscription(): { isLoading: boolean, isError: boolean, sub: CurrentSubscriptionInfo | undefined } {
  const currentOrg = useCurrentOrg();
  const { data, isLoading, isError } = appApi.useGetOrgSubscriptionQuery({ orgId: currentOrg?.id }, { skip: !currentOrg });
  const now = new Date();
  const sub = data?.subscription;
  let subInfo: CurrentSubscriptionInfo | undefined;
  if (sub) {
    subInfo = {
      endsAt: new Date(sub.endsAt),
      daysLeft: Math.floor(differenceInDays(sub.endsAt, now)),
    }
  } else {
    subInfo = undefined;
  }
  return { isLoading, isError, sub: subInfo };
}

export function subscriptionDaysDiff(subEnd: number | Date, now: number | Date): number {
  return Math.floor(differenceInDays(subEnd, now));
}
