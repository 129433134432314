import { useEffect, useRef } from "react";

type EventHandlerType<E extends string, S extends { addEventListener: (eventName: E, listener: any) => any }> =
  S extends { addEventListener: (eventName: E, listener: infer R) => any } ? R : never;

// Modified and typescriptified version of idea from https://usehooks.com/
export default function useEventListener<
  E extends string,
  S extends {
    addEventListener: (eventName: E, listener: any, options?: AddEventListenerOptions) => any,
    removeEventListener: (eventName: E, listener: any, options?: EventListenerOptions) => void
  },
  Handler extends (e: any) => void = EventHandlerType<E, S>,
>(
  subject: S | null | undefined,
  eventName: E,
  handler: Handler,
  options?: {
    enabled?: boolean,
    capture?: boolean,
  }
) {
  // Optimization (from https://usehooks.com/ - not sure how important):
  // since the handler is likely to change often,
  // avoid addEventListener/removeEventListener calls
  const savedHandler = useRef<Handler>();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      if ((options?.enabled ?? true) && subject !== null && subject !== undefined) {
        const eventListener = (e: E) => {
          if (savedHandler.current) {
            savedHandler.current(e);
          }
        };

        subject.addEventListener(eventName, eventListener, { capture: options?.capture });

        return () => {
          subject.removeEventListener(eventName, eventListener, { capture: options?.capture });
        };
      } else {
        return () => { };
      }
    },
    [options?.enabled, options?.capture, eventName, subject]
  );
}
