import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  FLUSH, PAUSE,
  PERSIST, persistReducer, persistStore, PURGE,
  REGISTER, REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { assetsApi } from 'src/api/assetsApi';
import { appApi } from '../api/api';
import accountReducer from './slices/accountSlice';
import authReducer from './slices/authSlice';
import tutorialReducer, { setTutorialState } from './slices/tutorialSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'auth', 'tutorial'],
};

const rootReducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [appApi.reducerPath]: appApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  auth: authReducer,
  account: accountReducer,
  tutorial: tutorialReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(appApi.middleware).concat(assetsApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

(global as any)._debug_resetTutorial = () => {
  store.dispatch(setTutorialState({}));
};
