import { NavSectionProps } from 'src/components/nav-section/type';
import { OrgAccessLevelInfo } from 'src/redux/slices/accountSlice';
import { useTutorialState } from 'src/redux/slices/tutorialSlice';
import { filterUndefined } from 'src/shared/misc';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`${process.env.PUBLIC_URL}/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  banking: getIcon('ic_banking'),
  kanban: getIcon('ic_kanban'),
  settings: getIcon('ic_settings'),
  help: getIcon('ic_help'),
};

export default function useNavConfig(accessLevel: OrgAccessLevelInfo): NavSectionProps['navConfig'] {
  const tutorialState = useTutorialState();
  const frontPageTutorialMessage = tutorialState.state === 'adding-accounts-done'
    ? "Katso kassatilanne täältä"
    : undefined;

  return [
    {
      subheader: 'Cashpulse',
      items: filterUndefined([
        { title: 'Etusivu', path: '/etusivu', icon: ICONS.dashboard, tutorialMessage: frontPageTutorialMessage },
        { title: 'Erääntyvät maksut', path: '/eraantyvat_maksut', icon: ICONS.ecommerce },
        { title: 'Maksujen optimointi', path: '/maksujen_optimointi', icon: ICONS.analytics },
        { title: 'Simulointi', path: '/simulointi', icon: ICONS.kanban },
        {
          title: 'Itse lisättävät tapahtumat',
          path: '/itse_lisattavat_tapahtumat',
          icon: ICONS.booking,
        },
        { title: 'Kassalähteet', path: '/kassalahteet', icon: ICONS.banking },
        accessLevel.isAtLeast('owner') ? { title: 'Käyttäjät', path: '/kayttajat', icon: ICONS.user } : undefined,
        { title: 'Asetukset', path: '/asetukset', icon: ICONS.settings },
        { title: 'Ohjeet', path: '/ohjeet', icon: ICONS.help },
      ]),
    },
  ];
}
