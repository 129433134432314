import Big from 'big.js';
import { z } from "zod";

// *****************************************************************************
// ***** These are stored. Remember backwards- and forwards-compatibility! *****
// *****************************************************************************

export const OrgAddress = z.object({
  streetAddress: z.string(),
  postalCode: z.string(),
  city: z.string(),
  countryCode: z.string(),
});
export type OrgAddress = z.infer<typeof OrgAddress>;

export const OrgBillingMethod = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('mail'),
  }),
  z.object({
    type: z.literal('email'),
    emailAddress: z.string(),
  }),
  z.object({
    type: z.literal('e-invoice'),
    eInvoiceServiceId: z.string(),
    eInvoiceAddress: z.string(),
  })
]);
export type OrgBillingMethod = z.infer<typeof OrgBillingMethod>;

const BigNumberString = z.string().refine(v => {
  try {
    Big(v);
    return true;
  } catch (e) {
    return false;
  }
});

// Stored as JSON. Maintain backwards compatibility,
// or do a careful migration when making this more restrictive.
export const OrgSettings = z.object({
  mainCurrency: z.string(),
  // Minor units of main currency
  alertThresholdForAllAccountsTotal: BigNumberString.optional(),
  // Minor units of main currency
  manualCreditThresholdForAllAccountsTotal: BigNumberString.optional(),

  mainAddress: OrgAddress.optional(),
  separateBillingAddress: OrgAddress.optional(),
  billingMethod: OrgBillingMethod.optional(),
});
export type OrgSettings = z.infer<typeof OrgSettings>;
