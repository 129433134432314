import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import _ from "lodash";
import { RefObject, useEffect, useLayoutEffect, useState } from "react";
import { usePoll, usePollUntilSettled } from "src/hooks/polling";
import useEventListener from "src/hooks/useEventListener";

export interface TutorialHighlightProps {
  show: boolean,
  elementToFollow: RefObject<HTMLElement | null | undefined>,
  onDismiss: () => void,
  waitForTargetToSettle?: boolean;
  tooltipText?: string,
  zIndex?: number,
}

type HighlightSpec = CircularHighlightSpec | RectangularHighlightSpec;

interface HighlightSpecBase {
  x: number,
  y: number,
  xExtent: number,  // Half of width of highlight
  yExtent: number,  // Half of height of highlight
  margin: number,
  windowWidth: number,
  windowHeight: number,
}

interface CircularHighlightSpec extends HighlightSpecBase {
  kind: 'circular',
  innerRadius: number,
  outerRadius: number,
}

interface RectangularHighlightSpec extends HighlightSpecBase {
  kind: 'rectangular',
}

const BigTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    padding: '15px 30px',
    fontSize: theme.typography.pxToRem(24),
  },
}));

export default function TutorialHightlight(props: TutorialHighlightProps) {
  const [targetSettled, setTargetSettled] = useState(!props.waitForTargetToSettle);
  const [highlightSpec, setHighlightSpec] = useState<HighlightSpec | undefined>(undefined);
  const [forcedUpdateCounter, setForcedUpdateCounter] = useState(0);
  const zIndex = props.zIndex ?? 1400;  // MUI tooltips have Z index 1500

  useEventListener(window, 'resize', () => setForcedUpdateCounter(forcedUpdateCounter + 1));
  useEventListener(window, 'scroll', () => setForcedUpdateCounter(forcedUpdateCounter + 1));

  // useLayoutEffect doesn't catch all changes caused by animations, so we resort to polling.
  const { restartPolling } = usePollUntilSettled({
    enabled: props.waitForTargetToSettle,
    intervalSeconds: 0.05,
    secondsUntilSettled: 0.1,
    getCurrent: () => props.elementToFollow.current?.getBoundingClientRect().toJSON(),
    onSettled: () => {
      setTargetSettled(true);
    }
  });
  // Backup polling in case e.g. a very low framerate causes problems with the above.
  usePoll({
    enabled: props.waitForTargetToSettle,
    intervalSeconds: 0.5,
    onTick: () => {
      setForcedUpdateCounter(forcedUpdateCounter + 1);
    },
  });

  const [prevElementToFollow, setPrevElementToFollow] = useState(props.elementToFollow);
  useEffect(() => {
    if (props.elementToFollow !== prevElementToFollow) {
      setPrevElementToFollow(props.elementToFollow);
      setTargetSettled(false);
      restartPolling();
    }
  }, [restartPolling, prevElementToFollow, props.elementToFollow]);

  const isWithinHighlight = (x: number, y: number) => {
    if (highlightSpec === undefined) {
      return false;
    }
    const dx = x - highlightSpec.x;
    const dy = y - highlightSpec.y;
    switch (highlightSpec.kind) {
      case 'circular':
        const d2 = dx * dx + dy * dy;
        return d2 <= highlightSpec.outerRadius * highlightSpec.outerRadius;
      case 'rectangular':
        return dx <= highlightSpec.xExtent && dy <= highlightSpec.yExtent;
    }
  };

  useEventListener(window, 'click', (e: MouseEvent) => {
    if (!isWithinHighlight(e.clientX, e.clientY)) {
      e.preventDefault();
      e.stopPropagation();
    }
    props.onDismiss?.();
  }, { enabled: highlightSpec !== undefined, capture: true });

  useEffect(() => {
    setForcedUpdateCounter(forcedUpdateCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.elementToFollow.current]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (props.show && props.elementToFollow.current) {
      let { x, y, width, height } = props.elementToFollow.current.getBoundingClientRect();
      width = Math.max(1, width);
      height = Math.max(1, height);
      x += width * 0.5;
      y += height * 0.5;
      const aspect = width / height;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let newHighlightSpec: HighlightSpec;
      if (aspect < 1.1 && 1 / aspect < 1.1) {
        const innerRadius = Math.max(width, height) * 0.75;
        const outerRadius = Math.max(width, height) * 0.95;
        const xExtent = outerRadius, yExtent = outerRadius;
        const margin = outerRadius - innerRadius;
        newHighlightSpec = { kind: 'circular', x, y, xExtent, yExtent, margin, innerRadius, outerRadius, windowWidth, windowHeight };
      } else {
        const xExtent = width * 0.5;
        const yExtent = height * 0.5;
        const margin = 20;
        newHighlightSpec = { kind: 'rectangular', x, y, xExtent, yExtent, margin, windowWidth, windowHeight };
      }
      if (!_.isEqual(newHighlightSpec, highlightSpec)) {
        setHighlightSpec(newHighlightSpec);
      }
    } else {
      if (highlightSpec !== undefined) {
        setHighlightSpec(undefined);
      }
    }
  });

  if (!props.show) {
    return <></>;
  }

  const backdropColor = 'rgba(127, 127, 127, 0.5)';

  const backdropBackgroundSpec = (hs: HighlightSpec) => {
    switch (hs.kind) {
      case 'circular':
        return `radial-gradient(circle at ${hs.x}px ${hs.y}px, transparent 0%, transparent ${hs.innerRadius}px, ${backdropColor} ${hs.outerRadius}px)`;
      case 'rectangular':
        return undefined;
    }
  };
  const targetBoxShadowSpec = (hs: HighlightSpec) => {
    switch (hs.kind) {
      case 'circular':
        return undefined;
      case 'rectangular':
        return `inset 0px 0px 5px ${hs.margin}px ${backdropColor}, 0px 0px 0px 99999px ${backdropColor}`;
    }
  };

  // Style attributes for divs that surround the highlighted elements
  // and prevent clicks from passing through.
  const surrounderCommon = {
    position: 'fixed',
    zIndex: zIndex + 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  } as const;

  if (!targetSettled || highlightSpec === undefined) {
    return <div className="tutorial-highlight">
      <div
        className="tutorial-highlight-backdrop"
        style={{
          ...surrounderCommon,
          background: backdropColor,
        }}
      />
    </div>;
  }

  return <div className="tutorial-highlight">
    <div
      className="tutorial-highlight-surrounder-left"
      style={{
        ...surrounderCommon,
        right: highlightSpec.windowWidth - (highlightSpec.x - highlightSpec.xExtent),
      }}
    />
    <div
      className="tutorial-highlight-surrounder-right"
      style={{
        ...surrounderCommon,
        left: highlightSpec.x + highlightSpec.xExtent,
      }}
    />
    <div
      className="tutorial-highlight-surrounder-top"
      style={{
        ...surrounderCommon,
        bottom: highlightSpec.windowHeight - (highlightSpec.y - highlightSpec.yExtent),
      }}
    />
    <div
      className="tutorial-highlight-surrounder-bottom"
      style={{
        ...surrounderCommon,
        top: highlightSpec.y + highlightSpec.yExtent,
      }}
    />
    <div
      className="tutorial-highlight-backdrop"
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: backdropBackgroundSpec(highlightSpec),
        zIndex,
        pointerEvents: 'none',  // Click-through
      }}
    />
    {props.tooltipText !== undefined && (
      <BigTooltip
        open={true}
        arrow
        placement="right"
        title={props.tooltipText}
      >
        <div
          className="tutorial-highlight-target"
          style={{
            position: 'fixed',
            left: highlightSpec.x - highlightSpec.xExtent - highlightSpec.margin,
            top: highlightSpec.y - highlightSpec.yExtent - highlightSpec.margin,
            width: highlightSpec.xExtent * 2 + highlightSpec.margin * 2,
            height: highlightSpec.yExtent * 2 + highlightSpec.margin * 2,
            boxShadow: targetBoxShadowSpec(highlightSpec),
            zIndex: zIndex + 1,
            pointerEvents: 'none',
          }}
        />
      </BigTooltip>
    )}
  </div>;
}
