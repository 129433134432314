// Type definitions for Api shared between client and server.

import * as z from 'zod';
import { OrgAddress, OrgBillingMethod, OrgSettings } from './org-settings';

// --- General ---

// Using W3C regex instead of Zod's built-in e-mail regex: https://github.com/colinhacks/zod/issues/1515
export const EmailAddress = z.string().regex(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gu, "Not a valid e-mail address");
export type EmailAddress = z.infer<typeof EmailAddress>;


// --- OrgController ---

export const orgAccessLevels = ['owner', 'user', 'viewer'] as const;  // Ordered so that levels on the left have a superset of rights comapred to levels on the right
export const OrgAccessLevel = z.enum(orgAccessLevels);
export type OrgAccessLevel = z.infer<typeof OrgAccessLevel>;

export function orgAccessLevelIsAtLeast(scrutinee: OrgAccessLevel | undefined, requirement: OrgAccessLevel) {
  return !!scrutinee && orgAccessLevels.indexOf(scrutinee) <= orgAccessLevels.indexOf(requirement);
}

export const Organization = z.object({
  id: z.string(),
  name: z.string(),
});
export type Organization = z.infer<typeof Organization>;

export const OrgSubscriptionInfo = z.object({
  endsAt: z.number(),
});
export type OrgSubscriptionInfo = z.infer<typeof OrgSubscriptionInfo>;

export const CreateOrgRequest = z.object({
  orgName: z.string().min(4).max(100),
  businessId: z.string(),  // TODO: validate it here too?
  mainAddress: OrgAddress,
  separateBillingAddress: OrgAddress.optional(),
  billingMethod: OrgBillingMethod,
  contactName: z.string(),
  contactPhoneNumber: z.string(),
});
export type CreateOrgRequest = z.infer<typeof CreateOrgRequest>;
export const CreateOrgResponse = z.object({
  org: Organization,
})
export type CreateOrgResponse = z.infer<typeof CreateOrgResponse>;

export interface OrgMembershipResponse {
  orgMemberships: { org: Organization, subscription?: OrgSubscriptionInfo }[],
}

export const GetOrgSubscriptionResponse = z.object({
  subscription: OrgSubscriptionInfo.optional(),
});
export type GetOrgSubscriptionResponse = z.infer<typeof GetOrgSubscriptionResponse>;

export const OrgSettingsResponse = z.object({
  name: z.string(),
  businessId: z.string(),
  settings: OrgSettings,
});
export type OrgSettingsResponse = z.infer<typeof OrgSettingsResponse>;

export const UpdateOrgSettingsRequest = z.object({
  orgId: z.string().optional(),
  newSettings: OrgSettings,
});
export type UpdateOrgSettingsRequest = z.infer<typeof UpdateOrgSettingsRequest>;

export const OrgMembersResponse = z.object({
  members: z.array(z.object({
    userId: z.string(),
    name: z.string().optional(),
    email: z.string(),
    accessLevel: OrgAccessLevel,
  })),
});
export type OrgMembersResponse = z.infer<typeof OrgMembersResponse>;

export const SaveOrgMemberRequest = z.object({
  orgId: z.string().optional(),
  email: EmailAddress,
  accessLevel: OrgAccessLevel,
  sendInviteEmail: z.boolean(),
});
export type SaveOrgMemberRequest = z.infer<typeof SaveOrgMemberRequest>;

export const RemoveOrgMemberRequest = z.object({
  userId: z.string(),
  orgId: z.string().optional(),
});
export type RemoveOrgMemberRequest = z.infer<typeof RemoveOrgMemberRequest>;


// --- UserController ---

export const GetCurrentUserResponse = z.object({
  userId: z.string(),
  name: z.string().optional(),
  email: z.string(),
  phoneNumber: z.string().optional(),
  orgAccessLevels: z.record(z.string(), OrgAccessLevel),
  isSuperuser: z.boolean(),
});
export type GetCurrentUserResponse = z.infer<typeof GetCurrentUserResponse>;


// --- AccountController ---

export const CreateGoogleSheetsAccountRequest = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('newSheet'),
    orgId: z.string().optional(),
    name: z.string(),
  }),
  z.object({
    type: z.literal('existingSheet'),
    orgId: z.string().optional(),
    url: z.string().url(),
  }),
]);
export type CreateGoogleSheetsAccountRequest = z.infer<typeof CreateGoogleSheetsAccountRequest>;

export interface CreateGoogleSheetsAccountResponse {
  accountInfo: AccountInfo;
}

export interface GetAccountsResponse {
  accounts: AccountInfo[];
}

export const AccountType = z.enum(['csv', 'google-sheets', 'tilisy', 'netvisor', 'manual-predictions']);
export type AccountType = z.infer<typeof AccountType>;

export const BalanceType = z.enum(['FWAV', 'ITAV', 'ITBD', 'VALU'])
export type BalanceType = z.infer<typeof BalanceType>;

export const AccountInfo = z.object({
  id: z.string(),
  name: z.string(),
  accountType: AccountType,
  lastSyncedSeconds: z.number().optional(),
  alertThreshold: z.string().optional(),
  isSettled: z.boolean().optional(),
  isSucceeded: z.boolean().optional(),
  isFailed: z.boolean().optional(),
  googleSheetsId: z.string().optional(),
  googleSheetsUrl: z.string().optional(),
  currentBalanceMinorUnits: z.number().optional(),
  timeShiftBaseDate: z.string().optional(),
  fakeAccountType: z.string().optional(),
});
export type AccountInfo = z.infer<typeof AccountInfo>;

export interface GetAccountDetailsResponse {
  accountInfo: AccountInfo;
  googleSheetsId?: string;
  googleSheetsUrl?: string;
}

export const RenameAccountRequest = z.object({
  orgId: z.string().optional(),
  accountId: z.string(),
  newName: z.string(),
  tryToRenameGoogleSheetAlso: z.boolean().optional(),
});
export type RenameAccountRequest = z.infer<typeof RenameAccountRequest>;

export const UpdateAccountSettingsRequest = z.object({
  orgId: z.string().optional(),
  accountId: z.string(),
  newSettings: z.object({
    timeShiftBaseDate: z.string().optional(),
    fakeAccountType: z.string().optional(),
  }),
});
export type UpdateAccountSettingsRequest = z.infer<typeof UpdateAccountSettingsRequest>;

export interface StartLoginResponse {
  redirectUrl: string;
}

export const SyncRequest = z.object({
  orgId: z.string(),
  accountId: z.string(),
});
export type SyncRequest = z.infer<typeof SyncRequest>;

export interface SyncResponse {
  userFacingErrors: SyncResponseUserFacingMessage[],
}

export interface SyncResponseUserFacingMessage {
  type: 'rate-limit-info' | 'warning',
  text: string,
}

export const AccountExportRequest = z.object({
  orgId: z.string(),
  accountIds: z.array(z.string()),
});
export type AccountExportRequest = z.infer<typeof AccountExportRequest>;

export const AccountImportRequest = z.object({
  orgId: z.string(),
  dataFiles: z.array(z.object({ data: z.string() })),
});
export type AccountImportRequest = z.infer<typeof AccountImportRequest>;

export type ImportCsvResponse = SuccessfulImportCsvResponse | FailedImportCsvResponse;

export interface SuccessfulImportCsvResponse {
  success: true;
  numRows: number;
}

export interface FailedImportCsvResponse {
  success: false;
  userFacingErrors: string[];
}

export const GlobalTransactionKey = z.object({
  accountId: z.string(),
  key: z.string(),
});

export const GetTransactionsRequest = z.object({
  orgId: z.string().optional(),
  accountId: z.string().optional(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
  predictionsOnly: z.boolean().optional(),
  simulation: z.boolean().optional(),
});

export type GetTransactionsRequest = z.infer<typeof GetTransactionsRequest>;

export interface GetTransactionsResponse {
  accounts: AccountInfo[];
  transactions: TransactionForView[];
  currentBalanceMinorUnits?: number;
}

export interface TransactionForView {
  accountId: string;
  accountName: string;
  key: string;
  timeUnixSeconds: number;
  invoiceTimeUnixSeconds?: number;
  currency: string;
  minorUnits: string,
  isPrediction: boolean;
  counterparty: string;
  sourceReference: string;
  sourceComment: string;
  isOptimizable: boolean;
  optimizationFlexDays?: number;
  isUpdated?: boolean
}

// --- ManualPredictionsController ---

export interface GetManualPredictionsResponse {
  rules: ManualPredictionRuleInfo[],
}

export const ManualPredictionRuleCategory = z.enum(['prediction', 'simulation']);
export type ManualPredictionRuleCategory = z.infer<typeof ManualPredictionRuleCategory>;

export interface ManualPredictionRuleInfo {
  id: string;
  name: string;
  category: ManualPredictionRuleCategory;
  enabled: boolean;
  firstOccurrence: number;
  recurInterval?: RecurInterval;

  currency: string;
  minorUnits: string;

  counterparty: string;
  counterpartyName: string;
  sourceReference: string;
  sourceComment: string;

  hasOptimizedPayments?: boolean;

  createdAt: number;
}

export const BaseRecurInterval = z.object({
  lastOccurrence: z.string().optional(),  // For legacy reasons, this is a date representation parseable by `new Date`
});
export type BaseRecurInterval = z.infer<typeof BaseRecurInterval>;

export const DayOfWeekRecurInterval = BaseRecurInterval.extend({
  type: z.literal('day-of-week'),
});
export type DayOfWeekRecurInterval = z.infer<typeof DayOfWeekRecurInterval>;

export const DayOfEverySecondWeekRecurInterval = BaseRecurInterval.extend({
  type: z.literal('day-of-every-second-week'),
});
export type DayOfEverySecondWeekRecurInterval = z.infer<typeof DayOfEverySecondWeekRecurInterval>;

export const DayOfMonthRecurInterval = BaseRecurInterval.extend({
  type: z.literal('day-of-month'),
});
export type DayOfMonthRecurInterval = z.infer<typeof DayOfMonthRecurInterval>;

export const RecurInterval = z.discriminatedUnion('type', [
  DayOfWeekRecurInterval,
  DayOfEverySecondWeekRecurInterval,
  DayOfMonthRecurInterval,
]);
export type RecurInterval = z.infer<typeof RecurInterval>;


/// --- TilisyController ---

export type AuthMethodsType = {
  description: string,
  name: string,
  required: boolean,
  title: string
}
export type ServiceProviderType = {
  auth_methods: Array<AuthMethodsType>,
  country: string,
  logo: string,
  name: string,
  psu_types: Array<string>
}
export type AspspsType = {
  aspsps: Array<ServiceProviderType>
}
export type AmountType = {
  currency: string,
  amount: string
}
export type AddressType = 'Business' | 'Correspondence' | 'DeliveryTo' | 'MailTo' | 'POBox' | 'Postal' | 'Residential' | 'Statement'
export type PostalAddress = {
  address_type?: AddressType,
  department?: string,
  sub_department?: string,
  street_name?: string,
  building_numbder?: string,
  post_code?: string,
  town_name?: string,
  country_sub_division?: string,
  country?: string,
  address_line?: string[]
}
export type GenericIdentification = {
  identification: string,
  scheme_name: string,
  issuer?: string
}
export type PartyIdentification = {
  name?: string,
  postal_address?: PostalAddress,
  organisation_id?: GenericIdentification,
  private_id?: GenericIdentification
}
export type AccountIdentification = {
  iban?: string,
  other?: GenericIdentification
}
export type BankTransactionCode = {
  description: string,
  code: string,
  sub_code: string
}
export type CreditDebitIndicator = 'CRDT' | 'DBIT'
export type TransactionStatus = 'BOOK' | 'CNCL' | 'HOLD' | 'OTHR' | 'PDNG' | 'RJCT' | 'SCHD'
export type TilisyTransaction = {
  entry_reference?: string,
  merchant_category_code?: string,
  transaction_amount: AmountType,
  creditor?: PartyIdentification,
  creditor_account?: AccountIdentification,
  debtor?: PartyIdentification,
  debtor_account?: AccountIdentification,
  bank_transaction_code?: BankTransactionCode,
  credit_debit_indicator: CreditDebitIndicator,
  status: TransactionStatus,
  booking_date?: string,
  value_date?: string,
  transaction_date?: string,
  balance_after_transaction?: AmountType,
  reference_number?: string,
  remittance_information?: string[]
}
export type GetAccountTransactionsResponse = {
  transactions: TilisyTransaction[],
  continuation_key: string
}

export type TilisySessionResponse = {
  tilisySession: {
    sessionId: string,
    validFrom: Date,
    validUntil: Date
  }
}

export type OptimizerAccountTransactionBase = {
  accountId: string,
  key: string,
  time: Date
}

export type OptimizerAccountTransaction = OptimizerAccountTransactionBase &
{
  minorUnits: string,
  isOptimizable: boolean
}

export type OptimizerData = {
  alertThresholdForAllAccountsTotal: string,
  accountTransactions: OptimizerAccountTransaction[],
  logVerbosely: boolean,
}

export type ReturnOptimizerAccountTransaction = OptimizerAccountTransactionBase;

export type ReturnOptimizerData = {
  success: number,
  accountTransactions: ReturnOptimizerAccountTransaction[]
}

// --- AdminController ---

export const GetOrgListForAdminResponse = z.object({
  orgs: z.array(z.object({
    id: z.string(),
    name: z.string(),
    businessId: z.string(),
    createdAt: z.number(),
    latestSubscription: OrgSubscriptionInfo.optional(),
  })),
});
export type GetOrgListForAdminResponse = z.infer<typeof GetOrgListForAdminResponse>;

export const OrgUserInfo = z.object({
  id: z.string(),
  name: z.string().optional(),
  phoneNumber: z.string().optional(),
  email: z.string(),
  auth0Id: z.string().nullish(),
  createdAt: z.number(),
});
export type OrgUserInfo = z.infer<typeof OrgUserInfo>;

export const OrgMembershipInfo = z.object({
  user: OrgUserInfo,
  accessLevel: OrgAccessLevel,
});
export type OrgMembershipInfo = z.infer<typeof OrgMembershipInfo>;

export const GetOrgDetailsForAdminRequest = z.object({
  orgId: z.string(),
});
export type GetOrgDetailsForAdminRequest = z.infer<typeof GetOrgDetailsForAdminRequest>;
export const GetOrgDetailsForAdminResponse = z.object({
  latestSubscription: OrgSubscriptionInfo.optional(),
  memberships: z.array(OrgMembershipInfo),
});
export type GetOrgDetailsForAdminResponse = z.infer<typeof GetOrgDetailsForAdminResponse>;

export const UpdateOrgSubscriptionRequest = z.object({
  orgId: z.string(),
  newEndDate: z.number(),
});
export type UpdateOrgSubscriptionRequest = z.infer<typeof UpdateOrgSubscriptionRequest>;
