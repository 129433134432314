import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { appApi } from "src/api/api";
import AlertDialog from "src/components/AlertDialog";
import LoadingScreen from "src/components/LoadingScreen";
import { useClientConfig } from "src/config";
import { useAppDispatch } from "src/hooks/redux";
import { logOut, setCurrentUser, setGlobalAuth0ForRTK, useAuthState } from "src/redux/slices/authSlice";

interface AuthGuardProps {
  loginRequired: boolean,
  children: ReactNode,
}

export default function AuthGuard({ loginRequired, children }: AuthGuardProps) {
  const clientConfig = useClientConfig();
  return <Auth0Provider
    domain={clientConfig.auth0.domain}
    clientId={clientConfig.auth0.clientId}
    redirectUri={window.location.origin + '/kirjautuminen'}
    scope="profile email openid login"
    useRefreshTokens={true}
    audience={clientConfig.baseUrl}
    cacheLocation="localstorage"
  >
    <AuthGuardWithAuthProvider loginRequired={loginRequired}>
      {children}
    </AuthGuardWithAuthProvider>
  </Auth0Provider>;
}

function AuthGuardWithAuthProvider({ loginRequired, children }: AuthGuardProps) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const auth0 = useAuth0();
  setGlobalAuth0ForRTK(auth0);
  const authState = useAuthState();
  const getUserQuery = appApi.useGetCurrentUserQuery(undefined, { skip: !auth0.isAuthenticated });

  useEffect(() => {
    setGlobalAuth0ForRTK(auth0);
    if (auth0.isAuthenticated && getUserQuery.isSuccess) {
      dispatch(setCurrentUser({ user: getUserQuery.data }));
    } else if (!auth0.isAuthenticated) {
      logOut(dispatch);
    }
  }, [auth0, authState, dispatch, getUserQuery.data, getUserQuery.isSuccess]);

  function onErrorAcked() {
    logOut(dispatch);
    window.location.reload();
  }

  if (auth0.isLoading || getUserQuery.isLoading) {
    return <LoadingScreen />;
  }

  if (auth0.error) {
    console.error("Auth0 error", auth0.error);
    return <AlertDialog title="Virhe kirjautumisessa" text="" onOk={onErrorAcked} />;
  }

  if (getUserQuery.isError) {
    console.error("Error getting user", getUserQuery.error);
    return <AlertDialog title="Virhe käyttäjätietojen hakemisessa" text="" onOk={onErrorAcked} />;
  }

  if (auth0.isAuthenticated && pathname === '/kirjautuminen') {
    return <Navigate to={"/"} />;
  }

  if (!auth0.isAuthenticated && loginRequired) {
    if (pathname !== '/kirjautuminen') {
      return <Navigate to={"/kirjautuminen"} />;
    } else {
      return <Outlet />;
    }
  }

  return <>{children}</>;
}
