import { Typography } from "@mui/material";
import ExtendSubscriptionLink from "src/components/ExtendSubscriptionLink";
import { useCurrentSubscription } from "src/utils/subscription-utils";

export default function SubscriptionStatus() {
  const { sub } = useCurrentSubscription();
  if (sub) {
    if (sub.daysLeft < 0) {
      return <Typography color="error" fontWeight="bold">
        Cashpulse-tilauksesi on päättynyt.<br />
        <ExtendSubscriptionLink />
      </Typography>;
    } else if (sub.daysLeft <= 14) {
      return <Typography color="orange" fontWeight="bold">
        Cashpulse-tilauksesi päättyy {sub.daysLeft} pv päästä.<br />
        <ExtendSubscriptionLink />
      </Typography>;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
