import { useAuth0 } from '@auth0/auth0-react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { appApi } from 'src/api/api';
import { useAppSelector } from 'src/hooks/redux';
import { OrgAccessLevel } from 'src/shared/types';
import { checkedTypeCast } from 'src/utils/types';
import type { AppDispatch, RootState } from '../store';

interface User {
  userId: string,
  name?: string,
  email: string,
  phoneNumber?: string,
  orgAccessLevels: Record<string, OrgAccessLevel>,
  isSuperuser: boolean,
};
interface AuthState {
  user?: User,
};

const slice = createSlice({
  name: 'auth',
  initialState: checkedTypeCast<AuthState>({}),
  reducers: {
    setCurrentUser: (state, { payload: { user } }: PayloadAction<{ user: User }>) => {
      state.user = user;
    },
    setAsLoggedOut: (state) => {
      state.user = undefined;
    },
  },
});

export const { setCurrentUser, setAsLoggedOut } = slice.actions;

export function logOut(dispatch: AppDispatch, options?: { localOnly?: boolean }) {
  dispatch(slice.actions.setAsLoggedOut());
  dispatch(appApi.util.resetApiState());
  if (globalAuth0ForRTK) {
    const auth0 = globalAuth0ForRTK;
    if (auth0.isAuthenticated) {
      auth0.logout({ returnTo: window.location.origin + '/kirjautuminen', localOnly: options?.localOnly });
    }
  }
}

export default slice.reducer;

export const useAuthState = () => useAppSelector((state: RootState) => state.auth);
export const useCurrentUser = () => useAppSelector((state: RootState) => state.auth.user);

// We don't seem to have a clean way to provide the result of useAuth0 to RTK queries. Yay prescriptive enterprise frameworks.
export let globalAuth0ForRTK: ReturnType<typeof useAuth0> | undefined = undefined;
export function setGlobalAuth0ForRTK(auth0: ReturnType<typeof useAuth0> | undefined) {
  globalAuth0ForRTK = auth0;
}
