export function check(condition: any, getMessage?: () => any): asserts condition {
  if (!condition) {
    if (getMessage !== undefined) {
      throw new Error(`Assertion failed: ${getMessage()}`);
    } else {
      throw new Error('Assertion failed');
    }
  }
}

export function checkNotNullable<T>(condition: T): NonNullable<T> {
  if (condition === undefined || condition === null) {
    throw new Error('Expected value to be non-nullable');
  }
  return condition as NonNullable<T>;
}

export function safeParseInt(s: string): number | undefined {
  const n = Number.parseInt(s, 10);
  return Number.isSafeInteger(n) ? n : undefined;
}

export function safeIntSum(a: number, b: number): number | undefined {
  const c = a + b;
  return Number.isSafeInteger(c) ? c : undefined;
}

export function safeIntDifference(a: number, b: number): number | undefined {
  const c = a - b;
  return Number.isSafeInteger(c) ? c : undefined;
}

export function falsyToUndefined<T>(x: T): NonNullable<T> | undefined {
  return x ? x as NonNullable<T> : undefined;
}

export function filterUndefined<T>(xs: T[]): Exclude<T, undefined>[] {
  return xs.filter(x => x !== undefined) as Exclude<T, undefined>[];
}

export function errorStack(e: unknown): string | undefined {
  if (e instanceof Error) {
    return e.stack;
  } else {
    return undefined;
  }
}

type UnwrapPromise<T extends Promise<any>> = T extends Promise<infer P> ? P : unknown;

export async function awaitAll<T extends { [k: string]: Promise<any> }>(obj: T): Promise<{ [P in keyof T]: UnwrapPromise<T[P]> }> {
  const result: { [P in keyof T]?: UnwrapPromise<T[P]> } = {};
  for (const prop of Object.getOwnPropertyNames(obj)) {
    result[prop as keyof T] = await obj[prop];
  }
  return result as { [P in keyof T]: UnwrapPromise<T[P]> };
}

/// Returns a promise that never completes
export function never<T>(): Promise<T> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise<T>((resolve, reject) => { });
}

export function finnishBusinessIdIsValid(id: string | undefined): boolean {
  // https://tarkistusmerkit.teppovuori.fi/tarkmerk.htm#y-tunnus2
  const m = (id ?? '').match(/^([0-9]{6,7})-([0-9])$/);
  if (!m) {
    return false;
  }
  const n = Number.parseInt(m[1], 10);
  const checksum = Number.parseInt(m[2], 10);
  const coefficients = [7, 9, 10, 5, 8, 4, 2];
  let sum = 0;
  for (let i = 0; i < 7; ++i) {
    const digit = Math.floor(n / Math.pow(10, 6 - i)) % 10;
    sum += digit * coefficients[i];
  }
  const remainder = sum % 11;
  if (remainder === 0) {
    return checksum === 0;
  } else {
    return checksum === 11 - remainder;
  }
}
