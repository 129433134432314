import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from 'src/hooks/redux';
import { OrgAccessLevel, orgAccessLevelIsAtLeast, Organization } from 'src/shared/types';
import type { RootState } from '../store';
import { useCurrentUser } from './authSlice';

interface AccountState {
  currentOrg?: Organization,
  selectingOrg: boolean,
}

const initialState: AccountState = {
  currentOrg: undefined,
  selectingOrg: true,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setCurrentOrg: (state, action: PayloadAction<Organization | undefined>) => {
      state.currentOrg = action.payload;
      state.selectingOrg = false;
    },
    clearCurrentOrg: (state) => {
      state.currentOrg = undefined;
      state.selectingOrg = true;
    },
    startSelectingOrg: (state) => {
      state.selectingOrg = true;
    },
    cancelSelectingOrg: (state) => {
      state.selectingOrg = false;
    },
  },
});

export const { setCurrentOrg, clearCurrentOrg, startSelectingOrg, cancelSelectingOrg } = accountSlice.actions;

export const useAccountState: () => AccountState = () => useAppSelector((state: RootState) => state.account);
export const useCurrentOrg: () => Organization | undefined = () => useAppSelector((state: RootState) => state.account.currentOrg);

export interface OrgAccessLevelInfo {
  readonly orgAccessLevel: OrgAccessLevel | undefined,
  readonly isAtLeast: (requirement: OrgAccessLevel) => boolean,
}

const emptyOrgAccessLevelInfo: OrgAccessLevelInfo = { orgAccessLevel: undefined, isAtLeast: (requirement) => false };

export const useCurrentAccessLevel: () => OrgAccessLevelInfo = () => {
  const user = useCurrentUser();
  const org = useCurrentOrg();
  if (!user || !org) {
    return emptyOrgAccessLevelInfo;
  }
  const orgAccessLevel: OrgAccessLevel | undefined = user.orgAccessLevels[org.id];
  if (!orgAccessLevel) {
    return emptyOrgAccessLevelInfo;
  }
  return {
    orgAccessLevel,
    isAtLeast: (requirement) => orgAccessLevelIsAtLeast(orgAccessLevel, requirement),
  };
};

export default accountSlice.reducer;
