import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router';
import { appApi } from 'src/api/api';
import { useAppDispatch } from 'src/hooks/redux';
import { setCurrentOrg } from 'src/redux/slices/accountSlice';
import { useCurrentUser } from 'src/redux/slices/authSlice';
import { Organization } from '../../../server/src/shared/types';
import LoadingScreen from './LoadingScreen';

export interface OrgPickerDialogProps {
  canCancel: boolean;
  onCancel?: () => void;
}

export default function OrgPickerDialog(props: OrgPickerDialogProps) {
  const location = useLocation();
  const currentUser = useCurrentUser();
  const { data, isLoading } = appApi.useGetOrgMembershipsQuery(null, { skip: !currentUser });
  const dispatch = useAppDispatch();

  const select = React.useCallback(
    (org: Organization) => {
      dispatch(setCurrentOrg(org));
    },
    [dispatch]
  );

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (/^\/uusi_organisaatio\/?/.test(location.pathname) || !currentUser) {
    return <></>;
  }
  if ((data?.length ?? 0) === 0) {
    return <Navigate to={"/uusi_organisaatio"} />;
  }
  return (
    <Dialog
      open={true}
      disableEscapeKeyDown={!props.canCancel}
      onClose={props.onCancel}
      aria-labelledby="org-picker-dialog-title"
      aria-describedby="org-picker-dialog-description"
    >
      <DialogTitle id="org-picker-dialog-title">Valitse organisaatio</DialogTitle>
      <DialogContent>
        <List>
          {_.sortBy(data, (item) => item.org.name).map((item) => (
            <ListItem key={item.org.id} button onClick={() => select(item.org)}>
              <ListItemText>{item.org.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
