import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import AppLogo from './AppLogo';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  withoutText?: boolean;
}

export default function Logo({ disabledLink = false, withoutText = true, sx }: Props) {
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <AppLogo withoutText={withoutText} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
