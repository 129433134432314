import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import fiLocale from 'date-fns/locale/fi';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import CurrentOrganizationGuard from './guards/CurrentOrganizationGuard';
import Router from './routes';
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';

export default function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
        <RtlLayout>
          <NotistackProvider>
            <CurrentOrganizationGuard>
              <MotionLazyContainer>
                <GlobalStyles />
                <ProgressBarStyle />
                {/*<Settings />*/}
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </CurrentOrganizationGuard>
          </NotistackProvider>
        </RtlLayout>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
