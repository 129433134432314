import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "src/hooks/redux";
import { checkedTypeCast } from "src/utils/types";
import type { RootState } from "../store";

type TutorialState = {
  state?: undefined,
} | {
  state: 'adding-accounts',
} | {
  state: 'adding-accounts-done',
} | {
  state: 'complete',
};

const slice = createSlice({
  name: 'tutorial',
  initialState: checkedTypeCast<TutorialState>({}),
  reducers: {
    setTutorialState: (state, action: PayloadAction<TutorialState>) => {
      console.info("New tutorial state:", action.payload);
      return action.payload;
    },
  }
});

export default slice.reducer;

export const { setTutorialState } = slice.actions;

export const useTutorialState = () => useAppSelector((state: RootState) => state.tutorial);
