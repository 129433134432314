import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { DASHBOARD_HEADER_MOBILE } from 'src/config';
import Logo from '../components/Logo';
import DashboardHeader from './dashboard/header';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  paddingTop: DASHBOARD_HEADER_MOBILE,
  paddingBottom: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('xs')]: {
    margin: theme.spacing(4.5, 0, 0, 0),
  },
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(4.5, 0, 0, 15),
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout(props: { outlet?: boolean, children?: ReactElement }) {
  const theme = useTheme();

  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <DashboardHeader showSubscriptionStatus={false} fixedWidth={`calc(100% - ${theme.spacing(15)})`} />
      <ContentStyle>
        {(props.outlet ?? true) && <Outlet />}
        {props.children}
      </ContentStyle>
    </>
  );
}
