import { Collapse, List } from '@mui/material';
import { RefObject, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { NavItemRoot, NavItemSub } from './NavItem';
import { NavListProps } from './type';
import TutorialHightlight from '../TutorialHighlight';

const getActive = (path: string, pathname: string) =>
  path ? !!matchPath({ path: path, end: false }, pathname) : false;

type NavListRootProps = {
  list: NavListProps;
  isCollapse: boolean;
};

export function NavListRoot({ list, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);
  const itemRef = useRef<HTMLElement | null>(null);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemTutorialHighlights item={list} itemRef={itemRef} />
        <NavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
          itemRef={itemRef}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => (
                <NavListSub key={item.title} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <>
    <NavItemTutorialHighlights item={list} itemRef={itemRef} />
    <NavItemRoot
      item={list}
      active={active}
      isCollapse={isCollapse}
      itemRef={itemRef} />
  </>;
}

function NavItemTutorialHighlights({ item, itemRef }: {
  item: NavListProps,
  itemRef: RefObject<HTMLElement | null>,
}) {
  const [dismissed, setDismissed] = useState(false);

  if (item.tutorialMessage === undefined) {
    return <></>;
  }

  return <TutorialHightlight
    show={!dismissed}
    elementToFollow={itemRef}
    onDismiss={() => setDismissed(true)}
    tooltipText={item.tutorialMessage}
  />;
}

type NavListSubProps = {
  list: NavListProps;
};

function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation();

  const activeRoot = getActive(list.path, pathname);

  const [open, setOpen] = useState(activeRoot);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={activeRoot} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={activeRoot} />;
}
