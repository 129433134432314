import { IconifyIcon } from '@iconify/react';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { ColorSchema } from '../theme/palette';
import Iconify from './Iconify';
import IconButtonAnimate from './animate/IconButtonAnimate';

export default function NotistackProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        iconVariant={{
          success: <SnackbarIcon icon={'eva:checkmark-circle-2-fill'} color="success" />,
          error: <SnackbarIcon icon={'eva:info-fill'} color="error" />,
          warning: <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />,
          info: <SnackbarIcon icon={'eva:alert-circle-fill'} color="info" />,
        }}
        action={(key) => (
          <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}

type SnackbarIconProps = {
  icon: IconifyIcon | string;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </Box>
  );
}
