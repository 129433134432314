import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './guards/AuthGuard';
import SubscriptionGuard from './guards/SubscriptionGuard';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard loginRequired={true}>
          <SubscriptionGuard>
            <DashboardLayout />
          </SubscriptionGuard>
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/etusivu" replace /> },
        { path: '/kirjautuminen', element: <LoginPage /> },
        { path: '/etusivu', element: <Frontpage /> },
        { path: '/eraantyvat_maksut', element: <PaymentList /> },
        { path: '/maksujen_optimointi', element: <Optimization /> },
        { path: '/simulointi', element: <Simulations /> },
        { path: '/itse_lisattavat_tapahtumat', element: <ManualPredictions /> },
        {
          path: '/kassalahteet',
          children: [
            { index: true, path: '', element: <Accounts /> },
            { path: 'muokkaa/:accountId', element: <EditAccountPage /> },
            { path: 'uusi_pankkitili', element: <NewBankAccountPage /> },
            { path: 'uusi_netvisor', element: <NewNetvisorAccountPage /> },
            { path: 'uusi_google_sheets', element: <NewGoogleSheetsAccountPage /> },
            { path: 'tuo_tili', element: <ImportAccountPage /> },
          ],
        },
        { path: '/kayttajat', element: <UsersPage /> },
        { path: '/asetukset', element: <Settings /> },
      ],
    },
    {
      path: '/uusi_organisaatio',
      element: <AuthGuard loginRequired={false}>
        <LogoOnlyLayout outlet={false}><NewOrgPage /></LogoOnlyLayout>
      </AuthGuard>,
    },
    {
      path: '/ohjeet',
      element: <AuthGuard loginRequired={false}><LogoOnlyLayout /></AuthGuard>,
      children: [
        { index: true, element: <UserManual /> },
        { path: ':page', element: <UserManual /> },
      ]
    },
    {
      path: '/yllapito',
      children: [
        {
          index: true, path: '', element: <AuthGuard loginRequired={true}>
            <LogoOnlyLayout outlet={false}><AdminPage /></LogoOnlyLayout>
          </AuthGuard>
        },
      ],
    },
    {
      // Legacy path
      path: '/v2',
      element: <V2Redirect replace />,
      children: [
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <AuthGuard loginRequired={false}><NotFound /></AuthGuard> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const LoginPage = Loadable(lazy(() => import('./pages/auth/LoginPage')));
const Frontpage = Loadable(lazy(() => import('./pages/Frontpage')));
const PaymentList = Loadable(lazy(() => import('./pages/PaymentList')));
const Optimization = Loadable(lazy(() => import('./pages/Optimization')));
const ManualPredictions = Loadable(
  lazy(() => import('./pages/manual-predictions/ManualPredictions'))
);
const Simulations = Loadable(lazy(() => import('./pages/manual-predictions/Simulations')));
const Accounts = Loadable(lazy(() => import('./pages/Accounts')));
const EditAccountPage = Loadable(lazy(() => import('./pages/account/EditAccountPage')));
const NewBankAccountPage = Loadable(lazy(() => import('./pages/account/NewBankAccountPage')));
const NewNetvisorAccountPage = Loadable(
  lazy(() => import('./pages/account/NewNetvisorAccountPage'))
);
const NewGoogleSheetsAccountPage = Loadable(
  lazy(() => import('./pages/account/NewGoogleSheetsAccountPage'))
);
const ImportAccountPage = Loadable(
  lazy(() => import('./pages/account/ImportAccountPage'))
);
const UsersPage = Loadable(lazy(() => import('./pages/UsersPage')));
const Settings = Loadable(lazy(() => import('./pages/Settings')));
const NotFound = Loadable(lazy(() => import('./pages/Page404')));
const V2Redirect = Loadable(lazy(() => import('./components/V2Redirect')));
const NewOrgPage = Loadable(lazy(() => import('./pages/NewOrgPage')));
const UserManual = Loadable(lazy(() => import('./pages/UserManual')));
const AdminPage = Loadable(lazy(() => import('./pages/admin/AdminPage')));
