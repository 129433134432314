import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const assetsApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.PUBLIC_URL }),
    endpoints: (builder) => ({
        getUserManualMarkdown: builder.query<string, { documentFilename: string }>({
            query: ({ documentFilename }) => ({
                url: `/user-manual/${documentFilename}`,
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
});
