import { Container, Typography } from "@mui/material";
import { ReactNode } from "react";
import ExtendSubscriptionLink from "src/components/ExtendSubscriptionLink";
import LoadingError from "src/components/LoadingError";
import LoadingScreen from "src/components/LoadingScreen";
import Page from "src/components/Page";
import { useClientConfig } from "src/config";
import DashboardLayout from "src/layouts/dashboard";
import { useCurrentOrg } from "src/redux/slices/accountSlice";
import { useCurrentSubscription } from "src/utils/subscription-utils";

export default function SubscriptionGuard({ children }: { children: ReactNode }) {
  const clientConfig = useClientConfig();
  const { sub, isLoading, isError } = useCurrentSubscription();
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (isError) {
    return <LoadingError />;
  }
  const disableDays = clientConfig.subscriptions.disableAppAfterSubscriptionExpiredForThisManyDays;
  if (sub && disableDays && sub.daysLeft < -disableDays) {
    return <SubscriptionHasEnded />;
  } else {
    return <>{children}</>;
  }
}

function SubscriptionHasEnded() {
  const currentOrg = useCurrentOrg();
  return <DashboardLayout outlet={false}>
    <Page>
      <Container>
        <Typography color="error" fontWeight="bold">
          Organisaation {currentOrg?.name} Cashpulse-tilaus on päättynyt.<br />
          <ExtendSubscriptionLink />
        </Typography>
      </Container>
    </Page>
  </DashboardLayout>;
}
