import { Alert, Button, Container, Stack } from "@mui/material";

/// Prefer `UnsuccessfulQuery` in most cases.
export default function LoadingError() {
  return <Container>
    <Stack spacing={3}>
      <Alert severity="error">Tietojen lataus epäonnistui</Alert>
      <Button
        variant="outlined"
        onClick={() => window.location.reload()}
      >Lataa sivu uudelleen</Button>
    </Stack>
  </Container>;
}
