import { Avatar, Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from 'src/hooks/redux';
import { startSelectingOrg, useCurrentOrg } from 'src/redux/slices/accountSlice';
import { useCurrentUser } from 'src/redux/slices/authSlice';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const currentUser = useCurrentUser();
  const currentOrg = useCurrentOrg();
  const dispatch = useAppDispatch();

  const handleOrgSelection = () => {
    dispatch(startSelectingOrg());
  };

  return (
    <Link underline="none" color="inherit" onClick={handleOrgSelection}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={`${process.env.PUBLIC_URL}/icons/ic_user.svg`} alt={currentUser?.name} />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {currentUser?.name ?? currentUser?.email}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {currentOrg?.name}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
