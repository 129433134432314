import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => {
  const fullTitle = title ? `${title} | Cashpulse` : 'Cashpulse';

  return <Box ref={ref} {...other}>
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
    {children}
  </Box>;
});

export default Page;
