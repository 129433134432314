interface Props {
  withoutText?: boolean;
  onClick?: () => void;
}

export default function AppLogo({ withoutText = false, onClick }: Props) {
  // TODO: light logo when using dark theme
  /*
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  */
  return withoutText ? (
    <img src="/logo/cg-logo.png" alt="Cashpulse" onClick={onClick} />
  ) : (
    <img src="/logo/cg-text-logo.png" alt="Cashpulse" onClick={onClick} />
  );
}
