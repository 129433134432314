import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { _getClientConfigWhereHooksCannotBeUsed } from 'src/config';
import { clearCurrentOrg } from 'src/redux/slices/accountSlice';
import { globalAuth0ForRTK, logOut } from 'src/redux/slices/authSlice';
import {
  AccountExportRequest,
  AccountImportRequest,
  AccountInfo,
  AspspsType,
  CreateGoogleSheetsAccountRequest,
  CreateGoogleSheetsAccountResponse,
  CreateOrgRequest,
  CreateOrgResponse,
  GetAccountsResponse, GetCurrentUserResponse, GetManualPredictionsResponse, GetOrgDetailsForAdminRequest, GetOrgDetailsForAdminResponse, GetOrgListForAdminResponse, GetOrgSubscriptionResponse, GetTransactionsRequest,
  GetTransactionsResponse, ManualPredictionRuleInfo,
  Organization,
  OrgMembershipResponse, OrgMembersResponse, OrgSettingsResponse,
  OrgSubscriptionInfo,
  RemoveOrgMemberRequest,
  RenameAccountRequest,
  ReturnOptimizerData,
  SaveOrgMemberRequest,
  SyncResponse,
  TilisySessionResponse, UpdateAccountSettingsRequest, UpdateOrgSettingsRequest, UpdateOrgSubscriptionRequest
} from 'src/shared/types';
import * as z from 'zod';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: async (headers) => {
    const auth0 = globalAuth0ForRTK;
    if (auth0?.isAuthenticated) {
      const response = await auth0.getAccessTokenSilently({
        audience: _getClientConfigWhereHooksCannotBeUsed().baseUrl,
        scope: 'profile email openid login',
        detailedResponse: true
      });
      headers.set('authorization', `Bearer ${response.access_token}`);
      headers.set('X-Requested-With', 'Cashpulse Web');
    }
    return headers;
  },
});

const ErrorData = z.object({ message: z.string().optional() }).optional();

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401 && api.endpoint !== 'getCurrentUser') {  // Errors from 'getCurrentUser' handled by login page.
      logOut(api.dispatch, { localOnly: true });
    } else {
      const errorDataParse = ErrorData.safeParse(result.error.data);
      if (errorDataParse.success && (errorDataParse.data?.message === 'Cannot access org' || errorDataParse.data?.message === 'No org selected')) {
        api.dispatch(clearCurrentOrg());
      }
    }
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: baseQueryWithAuth,
  // TODO: consider disabling caching - the perf / latency benefits don't seem to outweigh the bug-proneness, especially when other users may change data too.
  tagTypes: ['ClientConfig', 'CurrentUser', 'OrgMemberships', 'OrgMembers', 'OrgSettings', 'Transactions', 'Accounts', 'ManualPredictionRules'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<GetCurrentUserResponse, void>({
      query: () => ({ url: '/user/current-user', method: 'GET' }),
      transformResponse: (response: GetCurrentUserResponse) => ({
        ...response,
      }),
      providesTags: () => ['CurrentUser'],
    }),
    fetchTransactions: builder.query<GetTransactionsResponse, GetTransactionsRequest>({
      query: (body) => ({ url: '/accounts/transactions/query', method: 'POST', body }),
      transformResponse: (response: GetTransactionsResponse) => ({
        ...response,
        transactions: [...response.transactions?.reverse()],
      }),
      providesTags: (result, error, params) => ['Transactions'],
    }),
    createOrg: builder.mutation<CreateOrgResponse, CreateOrgRequest>({
      query: (body) => ({ url: '/org/create', body, method: 'POST' }),
      transformResponse: r => CreateOrgResponse.parse(r),
      invalidatesTags: (result, error, params) => ['OrgMemberships', 'OrgSettings', 'CurrentUser'],
    }),
    getOrgMemberships: builder.query<{ org: Organization, subscription?: OrgSubscriptionInfo }[], void | null>({
      query: () => ({ url: '/org/memberships', method: 'get' }),
      transformResponse: (response: OrgMembershipResponse) => response.orgMemberships,
      providesTags: (result, error, params) => ['OrgMemberships'],
    }),
    getOrgSubscription: builder.query<
      GetOrgSubscriptionResponse,
      { orgId?: string }
    >({
      query: (params) => ({ url: '/org/subscription', params, method: 'get' }),
      providesTags: (result, error, params) => ['OrgMemberships', 'OrgSettings'],
    }),
    getOrgSettings: builder.query<
      OrgSettingsResponse,
      { orgId?: string }
    >({
      query: (params) => ({ url: '/org/settings', params }),
      providesTags: (result, error, params) => ['OrgSettings'],
    }),
    updateOrgSettings: builder.mutation<void, UpdateOrgSettingsRequest>({
      query: (body) => ({ url: '/org/settings', body, method: 'POST' }),
      invalidatesTags: (result, error, params) => ['OrgSettings'],
    }),
    getOrgMembers: builder.query<
      OrgMembersResponse,
      { orgId?: string }
    >({
      query: (params) => ({ url: '/org/members', params }),
      providesTags: (result, error, params) => ['OrgMembers'],
    }),
    saveOrgMember: builder.mutation<
      void,
      SaveOrgMemberRequest
    >({
      query: (rule) => ({ url: '/org/save-member', method: 'POST', body: rule }),
      invalidatesTags: (result, error, params) => ['OrgMembers'],
    }),
    removeOrgMember: builder.mutation<
      void,
      RemoveOrgMemberRequest
    >({
      query: (rule) => ({ url: '/org/remove-member', method: 'POST', body: rule }),
      invalidatesTags: (result, error, params) => ['OrgMembers'],
    }),
    getOptimization: builder.mutation<
      ReturnOptimizerData,
      { orgId?: string; startDate: number; endDate?: number }
    >({
      query: (params) => ({ url: '/optimizer/optimization', params, method: 'get' }),
    }),
    getManualPredictionRules: builder.query<
      ManualPredictionRuleInfo[],
      { orgId?: string; category: string }
    >({
      query: (params) => ({ url: '/manual-predictions', params, method: 'get' }),
      transformResponse: (response: GetManualPredictionsResponse) => response.rules,
      providesTags: (result, error, params) => ['ManualPredictionRules'],
    }),
    saveManualPredictionRule: builder.mutation<
      ManualPredictionRuleInfo,
      { orgId?: string; rule: ManualPredictionRuleInfo }
    >({
      query: (rule) => ({ url: '/manual-predictions/save-rule', method: 'POST', body: rule }),
      invalidatesTags: (result, error, params) => ['ManualPredictionRules', 'Accounts', 'Transactions'],
    }),
    deleteManualPredictionRule: builder.mutation<void, { orgId?: string; id: string }>({
      query: (params) => ({ url: '/manual-predictions/delete-rule', method: 'POST', body: params }),
      invalidatesTags: (result, error, params) => ['ManualPredictionRules', 'Accounts', 'Transactions'],
    }),
    // fetch all accounts & tilisy account sessions
    getAccounts: builder.query<
      { accounts: AccountInfo[]; sessions: TilisySessionResponse[] },
      { orgId?: string }
    >({
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const accountResult = await fetchWithBQ({ url: '/accounts', method: 'get', params });
        // eslint-disable-next-line
        if (accountResult.error) throw accountResult.error;
        const accounts = (accountResult?.data as GetAccountsResponse)?.accounts as AccountInfo[];
        const sessionsResults = await Promise.all(
          accounts.map((a) =>
            fetchWithBQ({
              url: '/tilisy/fetch-latest-session',
              method: 'get',
              params: { orgId: params.orgId, id: a.id },
            })
          )
        );
        const sessionFetchError = sessionsResults.find((r) => r.error);
        return sessionFetchError
          ? { error: sessionFetchError as FetchBaseQueryError }
          : {
            data: {
              accounts,
              sessions: sessionsResults.map((s) => s.data as TilisySessionResponse),
            },
          };
      },
      providesTags: (result, error, params) => ['Accounts'],
    }),
    getAccountDetails: builder.query<
      { accountInfo: AccountInfo },
      { orgId?: string, accountId: string }
    >({
      query: ({ orgId, accountId }) => ({ url: `/accounts/${accountId}/details`, params: { orgId }, method: 'get' }),
      providesTags: (result, error, params) => ['Accounts'],
    }),
    syncAccounts: builder.mutation<SyncResponse, { orgId?: string }>({
      query: (body) => ({ url: '/accounts/sync-all', body, method: 'post' }),
      invalidatesTags: (result, error, params) => ['Transactions', 'Accounts'],
    }),
    getAccountExport: builder.query<unknown, AccountExportRequest>({
      query: (body) => ({ url: `/accounts/export`, body, method: 'post' }),
      providesTags: (result, error, params) => ['Accounts', 'Transactions', 'ManualPredictionRules'],
    }),
    importAccounts: builder.mutation<unknown, AccountImportRequest>({
      query: (body) => ({ url: '/accounts/import', body, method: 'post' }),
      invalidatesTags: (result, error, params) => ['Accounts', 'Transactions', 'ManualPredictionRules'],
    }),
    getPaymentServiceProviders: builder.query<AspspsType, { orgId?: string }>({
      query: (params) => ({ url: '/tilisy/aspsps', params, method: 'get' }),
    }),
    startAccountAuthentication: builder.mutation<
      string,
      { name: string; country: string; orgId?: string }
    >({
      query: (params) => ({ url: '/tilisy', params, method: 'get' }),
    }),
    createNetvisorIntegration: builder.mutation<
      SyncResponse,
      { customerId: string; customerKey: string; orgId?: string }
    >({
      query: (body) => ({ url: '/netvisor/new-netvisor-integration', body, method: 'post' }),
      invalidatesTags: (result, error, params) => ['Accounts'],
    }),
    renameAccount: builder.mutation<void, RenameAccountRequest>({
      query: ({ orgId, accountId, newName }) => ({
        url: `/accounts/${accountId}/name`,
        body: { orgId, newName },
        method: 'post',
      }),
      invalidatesTags: (result, error, params) => ['Accounts'],
    }),
    updateAccountSettings: builder.mutation<void, UpdateAccountSettingsRequest>({
      query: ({ orgId, accountId, newSettings }) => ({
        url: `/accounts/${accountId}/settings`,
        method: 'post',
        body: { orgId, newSettings },
      }),
      invalidatesTags: (result, error, params) => ['Accounts'],
    }),
    deleteAccount: builder.mutation<void, { orgId?: string, accountId: string }>({
      query: ({ accountId, orgId }) => ({
        url: `/accounts/${accountId}/delete`,
        body: { orgId },
        method: 'post',
      }),
      invalidatesTags: (result, error, params) => ['Accounts', 'Transactions'],
    }),
    createGoogleSheetsAccount: builder.mutation<
      CreateGoogleSheetsAccountResponse,
      CreateGoogleSheetsAccountRequest
    >({
      query: (body) => ({
        url: '/accounts/new-google-sheets-account',
        body,
        method: 'post',
      }),
      invalidatesTags: (result, error, params) => ['Accounts'],
    }),
    shareGoogleSheet: builder.mutation<
      CreateGoogleSheetsAccountResponse,
      { orgId?: string; accountId: string; emails: string[] }
    >({
      query: ({ accountId, ...body }) => ({
        url: `/accounts/${accountId}/share-google-sheet`,
        body,
        method: 'post',
      }),
    }),
    getOrgListForAdmin: builder.query<GetOrgListForAdminResponse, undefined>({
      query: (params) => ({ url: '/admin/org-list', params, method: 'get' }),
      providesTags: (result, error, params) => ['OrgSettings'],
    }),
    getOrgDetailsForAdmin: builder.query<GetOrgDetailsForAdminResponse, GetOrgDetailsForAdminRequest>({
      query: (params) => ({ url: '/admin/org-details', params, method: 'get' }),
      providesTags: (result, error, params) => ['OrgSettings'],
    }),
    updateOrgSubscriptionAsAdmin: builder.mutation<void, UpdateOrgSubscriptionRequest>({
      query: (body) => ({ url: '/admin/org-subscription', body, method: 'post' }),
      invalidatesTags: (result, error, params) => ['OrgSettings'],
    }),
  }),
});

// TODO: don't export individual use* functions. Instead make all calls through `appApi`.
//       This reduces busywork to maintain this list and helps IDE autocomplete.
export const {
  useFetchTransactionsQuery,
  useGetOrgSettingsQuery,
  useGetOptimizationMutation,
  useDeleteManualPredictionRuleMutation,
  useGetManualPredictionRulesQuery,
  useSaveManualPredictionRuleMutation,
  useUpdateOrgSettingsMutation,
  useGetAccountsQuery,
  useGetPaymentServiceProvidersQuery,
  useStartAccountAuthenticationMutation,
  useDeleteAccountMutation,
} = appApi;
