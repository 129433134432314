import { AppBar, Badge, Box, IconButton, Stack, Toolbar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { useCurrentUser } from 'src/redux/slices/authSlice';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import {
  DASHBOARD_HEADER_DESKTOP, DASHBOARD_HEADER_MOBILE, DASHBOARD_NAVBAR_COLLAPSE_WIDTH, DASHBOARD_NAVBAR_WIDTH, useClientConfig
} from '../../../config';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useResponsive from '../../../hooks/useResponsive';
import cssStyles from '../../../utils/cssStyles';
import AccountPopover from './AccountPopover';
import SubscriptionStatus from './SubscriptionStatus';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean | undefined,
  fixedWidth: string | undefined,
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'fixedWidth',
})<RootStyleProps>(({ isCollapse, fixedWidth, theme }) => ({
  boxShadow: 'none',
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
  width: fixedWidth,
  [theme.breakpoints.up('lg')]: {
    width: fixedWidth ?? `calc(100% - ${DASHBOARD_NAVBAR_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: fixedWidth ?? `calc(100% - ${DASHBOARD_NAVBAR_COLLAPSE_WIDTH}px)`,
    }),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: DASHBOARD_HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: DASHBOARD_HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

interface Props {
  showSubscriptionStatus?: boolean,  // Defaults to true, but overridden by subscriptions being disabled in config
  onOpenSidebar?: VoidFunction,
  fixedWidth?: string,
};

export default function DashboardHeader({ showSubscriptionStatus, onOpenSidebar, fixedWidth }: Props) {
  const config = useClientConfig();
  showSubscriptionStatus = (showSubscriptionStatus ?? true) && (config.subscriptions.enabled ?? false);

  const { isCollapse } = useCollapseDrawer();

  const currentUser = useCurrentUser();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle isCollapse={isCollapse} fixedWidth={fixedWidth}>
      <ToolbarStyle>
        {!isDesktop && onOpenSidebar && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {showSubscriptionStatus && (
            <ErrorBoundary errorComponent={<></>}>
              <SubscriptionStatus />
            </ErrorBoundary>
          )}
          {currentUser?.isSuperuser && <AdminButton />}
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

function AdminButton() {
  const navigate = useNavigate();

  return <Tooltip title="Ylläpito">
    <IconButton
      size="large"
      color='default'
      onClick={() => navigate('/yllapito')}
    >
      <Badge
        badgeContent={<Iconify icon="eva:shield-fill" width={20} height={20} color="#2c46ec " />}
        color="default"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Iconify icon="eva:settings-2-fill" width={20} height={20} />
      </Badge>
    </IconButton>
  </Tooltip>;
}
