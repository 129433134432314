import React from "react";

interface Props {
  errorComponent?: React.ReactNode,
  children?: React.ReactNode,
}

interface State {
  hasError: boolean,
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(errorInfo, error);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.errorComponent) {
        return this.props.errorComponent;
      } else {
        return <div className="error">Sovelluksen sisäinen virhe.</div>;
      }
    }

    return this.props.children;
  }
}
